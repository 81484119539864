import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import Plaatjie from '@ubo/plaatjie'

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.color.light};
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);

  &:before {
    position: absolute;
    content: '';
    width: 10px;
    height: 100px;
    top: 0;
    left: 0;
    background-color: #105e94;
    z-index: 9;
  }
`

const Title = styled.h2`
  font-family: ${({ theme }) => theme.font.family.secondary};
  word-break: break-word;

  @media (min-width: 1200px) {
    font-size: ${({ theme }) => theme.font.size.large};
  }

  @media (min-width: 992px) {
    font-size: ${({ theme }) => theme.font.size.bigger};
  }

  @media (max-width: 991px) {
    font-size: ${({ theme }) => theme.font.size.big};
  }
`

const Span = styled.span`
  font-weight: ${({ theme }) => theme.font.weight.light};
`

const Detail = styled.div`
  margin-bottom: 0.75rem;
  & h3 {
    font-size: ${({ theme }) => theme.font.size.medium};
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }
`

interface ReferenceProps {
  // eslint-disable-next-line
  blogFields: GatsbyTypes.WpPage_Flexcontent_Flex_References
  node: {
    id: string
    title: string
    recap: {
      delivery: string
      scope: string
      shipyard: string
      owner: string
    }
    referencecategories: {
      nodes: any
    }
  }
}

const Reference: React.FC<ReferenceProps> = ({ node }) => {
  const categories = node.referencecategories.nodes.map((node: any) => node)

  return (
    <Wrapper className="position-relative p-4 h-100">
      <div className="d-flex flex-column ps-3 h-100">
        <div className="mb-4 mb-lg-0">
          <Title className="mb-0">{node.title}</Title>
          {categories.map((category: any, index: number) => (
            <Span key={index}>{category.name}</Span>
          ))}
        </div>

        <div className="mt-auto mt-lg-4">
          <Detail>
            <h3 className="mb-0">Delivery</h3>
            <span>{node.recap.delivery}</span>
          </Detail>
          <Detail>
            <h3 className="mb-0">Scope of work</h3>
            <Span>{node.recap.scope}</Span>
          </Detail>
          <Detail>
            <h3 className="mb-0">Shipyard</h3>
            <Span>{node.recap.shipyard}</Span>
          </Detail>
          <Detail>
            <h3 className="mb-0">Owner</h3>
            <Span>{node.recap.owner}</Span>
          </Detail>
        </div>
      </div>
    </Wrapper>
  )
}

export default Reference
