import React, { useState } from 'react'
import styled from 'styled-components'
import { BlogBinkButton, useBlogBink } from '@ubo/blog-bink'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination } from 'swiper'

// Third party CSS
import 'swiper/css'
import 'swiper/css/pagination'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Interface
import { ReferencesProps } from '../ReferencesShell'

// Layout
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'
import ButtonSecondary from 'components/elements/Buttons/ButtonSecondary'
import Reference from './Reference'
import NoPosts from 'components/elements/Misc/NoPosts'

// SwiperCore modules
SwiperCore.use([Pagination])

const SwiperContainer = styled.div<{ marginLeft: number }>`
  max-width: initial !important;
  position: relative;
  width: calc(100% - ${({ marginLeft }) => marginLeft}px + 0.75rem) !important;
  margin-left: ${({ marginLeft }) => marginLeft}px !important;

  & .swiper {
    padding-right: 1.5rem;
  }
`

const Content = styled(ParseContent)`
  @media (min-width: 992px) {
    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      font-size: ${({ theme }) => theme.font.size.ultra};
    }
  }
`

const Grid: React.FC<ReferencesProps> = ({ fields, slider }) => {
  const blogBink = useBlogBink()

  const [marginLeft, setMarginLeft] = React.useState<number | null>(null)
  const containerRef = React.useRef(null)

  const handleResize = () => {
    if (!containerRef.current) {
      return
    }

    const nodeStyle = window.getComputedStyle(containerRef.current)
    const nodeMarginRight = nodeStyle.getPropertyValue('margin-left')

    setMarginLeft(Number(nodeMarginRight.replace('px', '')))
  }

  React.useEffect(() => {
    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', () => handleResize)
    }
  }, [])

  return (
    <>
      <div className="container" ref={containerRef}>
        {fields.description && (
          <div className="row">
            <div className="col-lg-4">
              <Content content={fields.description} />
            </div>
            <div className="col-lg-8">
              <Content content={fields.descriptiontwo} />
            </div>
          </div>
        )}

        <div
          className={`row ${
            slider ? 'd-lg-flex d-none' : 'd-flex pb-5 pb-lg-0'
          } `}
        >
          {blogBink.posts.length > 0 &&
            blogBink.posts.map((post, index) => {
              const { node }: any = post

              return (
                <div
                  key={post.node.id}
                  className="col-sm-6 col-lg-4 mt-4 mt-xl-5"
                >
                  <Reference node={node} blogFields={fields} />
                </div>
              )
            })}
          {blogBink.posts.length === 0 && (
            <NoPosts
              title="That ship has sailed..."
              body="Sorry! We couldn't find any references that meet this criteria. Please try another category"
            />
          )}
          {fields.link && (
            <div className="d-flex justify-content-center my-5">
              <ButtonPrimary to={fields.link?.url || '#'}>
                {fields.link?.title}
              </ButtonPrimary>
            </div>
          )}
          {blogBink.showMoreButton && (
            <div className="mt-5 text-center">
              <ButtonSecondary to="/" isCustom>
                <BlogBinkButton>{fields.loadmoretext}</BlogBinkButton>
              </ButtonSecondary>
            </div>
          )}
        </div>
      </div>

      {marginLeft !== null && slider && (
        <SwiperContainer
          className="container d-lg-none"
          marginLeft={marginLeft}
        >
          <ParseContent content={fields.description} className="mb-5" />

          <Swiper
            breakpoints={{
              768: {
                slidesPerView: 2.25,
                spaceBetween: 30,
              },
              450: {
                slidesPerView: 1.75,
                spaceBetween: 20,
              },
              0: {
                slidesPerView: 1.25,
                spaceBetween: 10,
              },
            }}
          >
            {blogBink.posts.map((post, index) => {
              const { node }: any = post

              return (
                <SwiperSlide key={`${post.node.id}-${index}`}>
                  <Reference node={node} blogFields={fields} />
                </SwiperSlide>
              )
            })}
          </Swiper>
          {fields.link && (
            <div className="d-flex justify-content-center my-5">
              <ButtonPrimary to={fields.link?.url || '#'}>
                {fields.link?.title}
              </ButtonPrimary>
            </div>
          )}
          {blogBink.showMoreButton && (
            <div className="mt-5 text-center">
              <ButtonPrimary to="/" isCustom>
                <BlogBinkButton>{fields.loadmoretext}</BlogBinkButton>
              </ButtonPrimary>
            </div>
          )}
        </SwiperContainer>
      )}
    </>
  )
}

export default Grid
